<template>
  <main class="page-projects page-list-projects">
    <PageTitleContainer>
      <PageTitle removeMargin>Progetti</PageTitle>

      <div class="text-right" v-if="isAdmin">
        <a href="/files/export.zip" target="_blank" class="btn btn-outline-secondary mr-3">Esporta progetti</a>
        <router-link class="btn btn-primary font-weight-bold text-white" :to="{ name: 'projects.new' }">Nuovo progetto</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-xl-4 mb-4" v-for="project in projects" :key="project.id">
          <ProjectCard :project="project" />
        </div>
      </div>

      <div v-if="projects && projects.length > 0" class="row justify-content-end align-items-center">
        <div class="col-auto text-right page-selector">
            <span class="mr-3">Risultati da mostrare</span>
            <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
        </div>
      </div>

      <AwlPagination v-if="projects && projects.length > 0" :value="page" :totalPages="totalPages" @page="goToPage" />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import rolesMixin from '@/mixins/roles';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listProjects()
    .then(res => next(vm => {
      vm.projects = res?.data?.projects || [];
      vm.setMetadata(res?.data);
    }))
    .catch(() => next(from))
  ;
}

export default {
  name: 'project-list',
  mixins: [pageableMixin, confirmWithModalMixin, rolesMixin],
  components: {
    PageTitle: () => import('@/views/components/Layout/PageTitle'),
    PageTitleContainer: () => import('@/views/components/Layout/PageTitleContainer'),
    ProjectCard: () => import('@/views/components/Card/ProjectCard'),
    AwlPagination: () => import('@/libs/Pagination/components/AwlPagination'),
    PerPageSelector: () => import('@/libs/Pagination/components/PerPageSelector'),
  },
  data () {
    return {
      projects: [],
    };
  },
  methods: {
    fetchResults (page = this.page) {
      return $api.listProjects(page, this.perPage)
        .then(res => {
          this.projects = res?.data?.projects || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
